@font-face {
  font-family:"Kollektif";
  src:url("/static/fonts/Kollektif.woff") format("woff");
}
Gbody {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

	background-color:rgba(0,255,0,0.1) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
	background:#ddd !important;
}

#root {
	display:flex;
	flex-direction: column;
}


html,body,#root {
	height:100%;
	margin:0;
}

.mainz {
	flex: 1 0 auto;
}

.sidenav {
  height: 100%;
  width: 150px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
}

.sidenav a {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
/*  font-size: 25px;*/
  color: #818181;
  display: block;
}

.sidenav i {
	width:22px;
	text-align:center;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav span {
	padding-left:5px;
}

.sidenav .active {
	background:rgba(255,255,255,0.2);
	color:#ffffff;
}

.mainheader {
	padding:5px;
	margin:0px -10px 0px -10px;
	background:#111;
	color:white;
	font-weight:700;
}

.main {
  margin-left: 150px; /* Same as the width of the sidenav */
/*  font-size: 28px*/; /* Increased text to enable scrolling */
  padding: 0px 10px;
}

@media screen and (max-width: 600px) {
	.sidenav {
		width:50px;
	}
	.main {
		margin-left:50px;
	}

	.sidenav span {
		display:none;
	}
}



/*
@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}
*/

/*
.selectablerow {
	cursor:pointer;
}
.selectablerow:hover {
	background:rgba(0,0,0,0.1);
}
*/

pre {
	margin:0 !important;
	color: inherit !important;
}


.topmenu {
	display:flex;
	position:sticky;
	background:rgba(255,255,255,0.9);
	top:-37px;
	z-index:1020;
}

.topmenu div {
	flex:1;
	margin:auto;
}

.topmenu span {
	display:block;
	font-size:0.5em;
	font-weight:700;
}

.topmenu .active {
	background:rgba(0,0,255,0.9);
	color:#ffffff !important;
}

.taskview {
	max-width:600px;
	margin:auto;
}

.customerview {
	margin-bottom:50px;
}

.footer {
	font-size:14px;
	font-family: "Open Sans";
	flex-shrink:0;
	margin-top:auto;
	background-color:rgba(46,46,46,0.99);
	color:#ffffff;
	padding:20px;

}

.footeradd {
	font-family: Viga;

	background: #b7bf10 ;
	padding:40px;
	margin:-20px -20px 0 -20px;
	font-size:25px;
	font-family:Viga;
	font-weight:400;
	text-align:center;

}

.nomga {
	padding-top:15px;
	color:rgba(255,255,255,1);
	text-align:right;
	width:100%;
}

.customerfilecontainer {
	display:flex;
	flex-wrap:wrap;
	margin:50px;
}

.customerfile {
	flex:1;
	flex-basis:300px;
	cursor:pointer;
	background:#ffffff;
	padding:5px;
	margin:5px;
	transition: 0.2s;
	text-align:center;
}

.customerfile:hover {
	box-shadow:1px 1px 5px 0px rgba(0,0,0,0.5);
}


.reportframe {
	background:#ffffff;
	margin:30px;
	padding:30px;
	box-shadow:2px 2px 5px 0px rgba(0,0,0,0.5);
}

.reportframe > .header {
	margin-bottom:20px;
}

.header > div > div {
	font-weight:700;
	display:inline-block;
	width:300px;
}

.pointdata {
	box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.5);
	padding:5px;
	margin-bottom:15px;
}

.pointtitle {
	margin-top:20px;
	margin-bottom:20px;
	border-bottom:solid 1px #000;
}
.pointname {
	font-weight:bold;
	font-size:1.5em;
	display:inline-block;
}

.pointdesc {
	margin-left:15px;
	display:inline-block;
}

.pointheader {
	width:100%;
	border-bottom:1px solid #000;
	margin-top:10px;
}

.pointheader > span {
	display:inline-block;
	font-weight:700;
	width:200px;
}

.pc {
}

.pc1 {
	display:inline-block;
	width:200px;
	margin-left:200px;
	font-weight:700;
}

.pc2 {
	display:inline-block;
	width:50px;
	text-align:right;
}

.pc3 {
	display:inline-block;
	padding-left:5px;
	font-weight:700;
}

.customer {
	font-size:1.3em;
	font-weight:700;
	margin-bottom:15px;
	text-decoration:underline;
}

.customername {
		font-weight:700;
		font-size:1.4em;
		text-align:center;
		margin:10px;
}

.customername > div {
	font-size:0.8em;
}

.chartholder {
	margin:50px;
	background:#ffffff;
	padding-top:20px;
	padding-bottom:20px;
}

