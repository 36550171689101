.logoholder {
	display: inline-block;
	white-space:nowrap;
	color:inherit;
}

.logoholder:hover {
	color:inherit;
	text-decoration:none;
}

.LOGO {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    padding-top: 3px;
}

.LOGO > div:nth-child(1) {
	font-family: Kollektif;
    font-size: 20px;
    line-height: 15px;
    border-bottom: solid 1px;
}

.LOGO > div:nth-child(2) {
	font-family: Lato;
    font-size: 10px;
    line-height: 10px;
    width: 100%;
}

.img {
		max-height:30px;
}

.big {
	max-height:22vw;

}

.big > div:nth-child(1) {
	font-size:15vw;
	line-height:15vw;
}

.big > div:nth-child(2) {
	font-size:7.5vw;
	line-height:7.5vw;
}


@media screen and (min-width:576px) and (max-width:767px) {
	.big {
		max-height:126px;
	}

	.big > div:nth-child(1) {
		font-size: 84px;
		line-height:84px;
	}
	.big > div:nth-child(2) {
		font-size: 42px;
		line-height: 42px;
	}
}

@media screen and (min-width:856px) {
	.big {
		max-height:186px;
	}

	.big > div:nth-child(1) {
		font-size: 124px;
		line-height:124px;
	}
	.big > div:nth-child(2) {
		font-size: 62px;
		line-height:62px;
	}


}




